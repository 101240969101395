export interface NotificationArchive {
    id: string;
    type: "in-app" | "push";
    audienceType: "houses" | "house-users" | "users" | null;
    audience: string[];
    title: string;
    text: string;
    createdAt: Date;
    creatorFullName: string;
    creatorAvatar: string | null;
}

export interface NotificationArchiveResponse {
    id: string;
    type: "in-app" | "push";
    audience_type: "houses" | "house-users" | "users";
    audience: string[];
    title: string;
    text: string;
    created_at: number;
    creator_full_name: string;
    creator_avatar: string | null;
}

export interface FindAllNotificationArchiveBody {
    page: number;
    limit: number;
    houseId?: string;
    apartment?: number;
    citizenId?: string;
    search: string;
    fromDate?: number;
    toDate?: number;
    signal: AbortSignal,
}

export interface CalcHouseAudienceBody {
    type: "houses";
    mode: "inclusive" | "exclusive";
    ids: string[];
}

export function mapToNotificationArchive(response: NotificationArchiveResponse): NotificationArchive {
    return {
        id: response.id,
        type: response.type,
        audienceType: response.audience_type || null,
        audience: response.audience,
        title: response.title,
        text: response.text,
        createdAt: new Date(response.created_at * 1000),
        creatorFullName: response.creator_full_name,
        creatorAvatar: response.creator_avatar,
    };
}