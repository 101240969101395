import {AuthorizedHttpClient} from "common/net";
import {CreateNotificationBody} from "../types";

export class NotificationRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(params: CreateNotificationBody): Promise<void> {
        return this.client.post(`notifications`, {
            body: {
                audience: params.audience.type === "house-users" ? {
                    type: "house-users",
                    house_id: params.audience.houseId,
                    mode: params.audience.mode,
                    ids: params.audience.ids,
                } : params.audience,
                notification: params.notification,
            },
        });
    }
}