import {
    CalcHouseAudienceBody,
    FindAllNotificationArchiveBody,
    mapToNotificationArchive,
    NotificationArchive,
    NotificationArchiveResponse
} from "citizens/types";
import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";

export class NotificationArchiveRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllNotificationArchiveBody): Promise<Pageable<NotificationArchive>> {
        const response = await this.client.get<PageableResponse<NotificationArchiveResponse>>(
            `/notifications`, {
                signal: params.signal,
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search || undefined,
                    house_id: params.houseId,
                    citizen_id: params.citizenId,
                    apartment: params.apartment,
                    from_date: params.fromDate ? params.fromDate / 1000 : undefined,
                    to_date: params.toDate ? params.toDate / 1000 : undefined,
                },
            });
        return mapPageable(response, mapToNotificationArchive);
    }

    async findAudienceCount(body: CalcHouseAudienceBody): Promise<number> {
        const response = await this.client.post<{ count: number }>(
            "/notifications/calculate-audience", {body},
        );

        return response.count;
    }

    async findById(id: string, signal: AbortSignal): Promise<NotificationArchive> {
        const response = await this.client.get<NotificationArchiveResponse[]>(
            `notifications/${id}`, {signal});
        //TODO: wait fix on backend
        return mapToNotificationArchive(response[0]);
    }

    async findAudienceById(id: string, signal: AbortSignal): Promise<Pageable<string>> {
        const response = await this.client.get<PageableResponse<string>>(
            `notifications/${id}/audience`, {signal});
        return mapPageable(response, item => item);
    }
}
